<script>
import { required,sameAs } from "vuelidate/lib/validators";
import {resetPassword,checkCode} from '@/apis/user'
import appConfig from "@/app.config";

/**
 * Forgot password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  props:{
    resetCode:{
      type: String,
    }
  },
  data() {
    return {
      title: "修改密码",
      validCode:true,
      user: {
        newpassword: "",
        renewpassword: "",
      },
      submitted: false,
      error: null,
      isUpdateError: false,    
      isSuccess:false
    };
  },
  validations: {
    user: {
      newpassword: {
        required,
      },
      renewpassword: {
        required,
        sameAsPassword: sameAs("newpassword") 
      },
    },
  },
  created(){
    if(this.resetCode==undefined || this.resetCode==null){
      this.validCode = false;
      this.isUpdateError = true;
      this.error = "无效的重置编码"
    }else{
      checkCode({"resetCode":this.resetCode}).then(()=>{
        this.validCode = true
        this.isUpdateError = false
      }).catch(()=>{
        this.validCode = false
        this.isUpdateError = true;
        this.error = "无效的重置编码"
      })
    }
  },
  methods: {
    tryToChangePassword() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.isUpdateError = false;
        resetPassword({...this.user,resetCode:this.resetCode}).then(()=>{
            this.isSuccess = true
        }).catch((res)=>{
            this.isSuccess = false
            if(res.message=="ERR_CODE_ERR"){
                this.error = "无效的重置码"
            }else{
                this.error = res.message
            }            
            this.isUpdateError=true;
        })
      }
    },
  },  
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="card overflow-hidden">
            <div class="bg-primary">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20 p-2">Reset Password</h5>
                <a href="/" class="logo logo-admin">
                  <img src="@/assets/images/logo-sm.svg" height="24" alt="logo" />
                </a>
              </div>
            </div>
            <div class="card-body p-4">
              <div class="p-3">
                <b-alert v-model="isUpdateError" class="mt-3" variant="danger" dismissible>{{error}}</b-alert>
                <b-alert v-model="isSuccess" class="mt-3" variant="success" dismissible>
                  <strong>密码已重置!</strong> 请返回登录页面登录.
                </b-alert>
                <b-form @submit.prevent="tryToChangePassword" class="form-horizontal mt-4" v-if="validCode">
                            <b-form-group
                                id="newpassword-group"
                                label="新密码"
                                label-for="newpassword"
                                label-class="form-label"
                                class="mb-3"
                            >
                                <b-form-input
                                id="newpassword"
                                v-model="user.newpassword"
                                type="password"
                                :class="{
                                    'is-invalid': submitted && $v.user.newpassword.$error,
                                }"
                                ></b-form-input>
                                <div
                                v-if="submitted && !$v.user.newpassword.required"
                                class="invalid-feedback"
                                >
                                请输入新密码
                                </div>
                            </b-form-group>    
                            <b-form-group
                                id="renewpassword-group"
                                label="确认密码"
                                label-for="renewpassword"
                                label-class="form-label"
                                class="mb-3"
                            >
                                <b-form-input
                                id="renewpassword"
                                v-model="user.renewpassword"
                                type="password"
                                :class="{
                                    'is-invalid': submitted && $v.user.renewpassword.$error,
                                }"
                                ></b-form-input>
                                <div
                                v-if="submitted && !$v.user.renewpassword.required"
                                class="invalid-feedback"
                                >
                                请输入确认密码
                                </div>
                                <div
                                v-if="submitted && !$v.user.renewpassword.sameAsPassword"
                                class="invalid-feedback"
                                >
                                密码输入不一致
                                </div>
                            </b-form-group>                                
                            <div class="form-group mb-0 text-center">
                                <div class="col-12 text-end">
                                <b-button type="submit" variant="primary" class="w-md"
                                    >提交</b-button
                                >
                                </div>
                            </div>
                        </b-form>   
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
              Remember It ?
              <router-link to="/login" class="fw-medium text-primary">Sign In here</router-link>
            </p>
            <p class="mb-0">
              © {{new Date().getFullYear()}} GreenPont
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
